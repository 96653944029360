<template>
	<div class="footer">
		<div class="footer-container">
			<div class="footer-public">
				<div class="footer-info">
					<div class="footer-baquan">
						<div class="footer-baquan-top">
							<p>四川省红十字会主办</p>
							<p>四川省红十字会版权所有</p>
							<p>Copyright 2006-2020 All Rights Reserved</p>
						</div>
						<div class="footer-banquan-bottom">
							<p>
								<a style="text-decoration:none; outline:none;font-weight: bold; color:#333; background:none;margin: 0 30px;" :href="datalist.badz">{{ datalist.bah || '' }}</a>
							</p>
							<p>{{ datalist.dwdz || '' }}</p>
							<p style="margin-left: 30px">邮箱：{{ datalist.yx || '' }}</p>
						</div>
					</div>
					<div class="wx">
						<img class="wxpublic" v-if="datalist.wx_qrcode" :src="datalist.wx_qrcode" alt="" />
						<img class="wbpublic" v-if="datalist.wo_qrcode" :src="datalist.wo_qrcode" alt="" />
						<div class="title">
							<p>
								<img src="../assets/images/微信.png" alt="">微信公众号
								<i></i>
							</p>
							<p style="margin-left: 30px;">
								<img src="../assets/images/微博.png" alt="">官方微博
								<i></i>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 关于我们 | 版权声明 | 联系我们 | 设为首页 | 设为首页 -->
		<div class="footer-list">
			<div class="fcontiner">
				<ul class="f-list">
					<li v-html="datalist.gywm"></li>
					<li v-html="datalist.bqsm"></li>
					<li v-html="datalist.lxwm"></li>
					<li>设为首页</li>
					<li v-html="datalist.jrwm"></li>
				</ul>
				<div class="f-shuoming">本网站由四川川大智胜系统集成有限公司提供技术支持</div>
			</div>
		</div>
	</div>
</template>

<script>
import apiUrls from '@api';
export default {
	data() {
		return {
			datalist: {}
		};
	},

	components: {},

	created() {
		this.getfooter();
	},

	mounted() {},

	methods: {
		getfooter() {
			apiUrls.yjData({ node: 'yj' }).then(res => {
				this.datalist = res.results.data;
				// console.log('页脚的信息',this.datalist)
			});
		}
	}
};
</script>
<style lang="less" scoped>
.footer {
	width: 100%;
	background-color: #ffffff;
	.footer-container {
		width: 1167px;
		// padding: 0 33px;
		margin: 0 auto;
		overflow: hidden;
		background-color: #ffffff;
		.footer-info {
			display: flex;
			justify-content: space-between;
			// height: 150px;
			.footer-baquan {
				text-align: left;
				display: flex;
				flex-direction: column;
				justify-content: center;
				line-height: 25px;
				font-size: 14px;
				font-weight: bold;
				color: #333333;
				.footer-baquan-top {
					margin-top: 25px;
				}
				.footer-banquan-bottom {
					display: flex;
					margin-top: 75px;
					p {
						&:nth-child(1) {
							position: relative;
							&::before {
								content: '';
								display: block;
								width: 22px;
								height: 24px;
								position: absolute;
								top: -4px;
								left: 0px;
								background: url('../assets/img/jinbiao.png') no-repeat center center;
							}
						}
						&:nth-child(2) {
							position: relative;
							margin-left: 20px;
							&::before {
								content: '';
								display: block;
								width: 22px;
								height: 24px;
								position: absolute;
								top: -4px;
								left: -25px;
								background: url('../assets/img/bottom-position.png') no-repeat center center;
							}
						}
					}
				}
			}
		}
		.wx {
			margin-top: 35px;
			.title {
				// margin-left: 11px;
				width: 270px;
				display: flex;
				justify-content: space-between;
				text-align: center;
				p {
					margin-left: 8px;
					display: flex;
					align-items: center;
					width: 100px;
					height: 25px;
					line-height: 25px;
					border-radius: 20px;
					font-size: 14px;
					font-weight: bold;
					position: relative;
					background-color: #ffffff;
					img {
						margin-right: 10px;
					}
					i {
						width: 0;
						height: 0;
						position: absolute;
						bottom: -16px;
						right: 39px;
						border-width: 8px;
						border-style: solid;
						border-color: #ffffff transparent transparent transparent;
					}
				}
			}
			.wxpublic,
			.wbpublic {
				margin-right: 41px;
				margin-top: 15px;
				width: 116px;
				height: 116px;
			}
		}
	}
	.footer-list {
		margin-top: 10px;
		height: 48px;
		background-color: #d72829;
		color: #ffffff;
		.fcontiner {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;
			margin: 0 auto;
			width: 1167px;
			padding: 0 33px;
			.f-list {
				display: flex;
				font-size: 18px;
				li {
					padding: 0 7px;
					border-right: 1px solid #ffffff;
					&:first-child {
						padding-left: 0;
					}
					&:last-child {
						border: none;
					}
				}
			}
			.f-shuoming {
				font-size: 14px;
			}
		}
	}
}
</style>
