<template>
  <div :class="flag?'home':''">
    <Header v-if="flag"></Header>
    <div
	  v-if="flag"
      class="breadMain"
      v-show="breadData&&breadData.length>0 && this.$route.path!='/home'">
      <img
        src="../assets/img/bread.png"
        alt=""
      >
      <p>您现在的位置:
        <span
          style="cursor:pointer"
          v-for="(item,index) in breadData"
          :key="index"
          @click="breadJump(item.path,index)"
        >{{item.title}}<span v-show="index<breadData.length-1 && breadData[index+1].title">&nbsp;&nbsp;>&nbsp;&nbsp;</span></span>
      </p>
    </div>
    <router-view :class="flag?'baseWidth content':''"></router-view>
    <Footer v-if="flag"></Footer>
  </div>
</template>

<script>
import Header from "@components/Header.vue";
import Footer from "@components/Footer.vue";
import Bus from '../utils/bus.js'
export default {
  name: "Home",
  data() {
    return {
      breadData: [],
	  flag: true
    };
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    breadJump(path, index) {
      let bread = JSON.parse(localStorage.getItem("bread"));
      let breadNow = [];
      this.$goto(path);
      if (index != 0) {
        breadNow = bread.slice(0, index);
      } else {
        breadNow = bread.slice(0, 1);
      }

      // if (index == 1) {
      //   breadNow = bread.slice(0, index + 1);
      // } else if (index >= 2) {
      // }
      // index != 0
      //   ? (breadNow = bread.slice(0, index + 1))
      //   : (breadNow = bread.slice(0, 1));
      localStorage.setItem("bread", JSON.stringify(breadNow));
    },
  },
  watch: {
    $route: function (val) {
		if(val.path == "/idonateAPP" || val.path == "/agree"){
			this.flag = false
		}else{
			this.flag = true
		}
		
      let bread = JSON.parse(localStorage.getItem("bread"));
      // console.log(val)
      let nbread = []
      if(!val.meta.breadNum){
        nbread.push({
          title:'首页',
          path:'/'
        })
      }
      if (val.meta.isFirst == 2) {
        console.log('router1',bread,val)
        bread.forEach((el,ind)=>{
          if(ind != (bread.length -1) && el.title != '首页'){
            nbread.push({ title: el.title, path: el.path });
          }else if(ind == (bread.length -1) ){
            nbread.push({ title: el.title, path: el.path });
            if(el.title != '详情'){
              nbread.push({ title: "详情", path: val.path });
            }
          } 
        })
        localStorage.setItem("bread", JSON.stringify(nbread));
      } else if (val.meta.isFirst == 1) {
        // console.log('router2',bread,val)
        nbread.push( {
          title: val.query.name + "列表",
          path: val.fullPath,
        });
        localStorage.setItem("bread", JSON.stringify(nbread));
      } else {
        if(val.meta.bread && val.meta.bread instanceof Array){
          val.meta.bread.forEach((el,ind) => {
            nbread.push( {
              title: el,
              path: val.fullPath,
            })
          });
          // console.log('router3',bread,nbread)
        }
        // bread[2] = {
        //   title: val.meta.bread[1] && val.meta.bread[1],
        //   path: val.fullPath,
        // };
        localStorage.setItem("bread", JSON.stringify(nbread));
      }
      this.breadData = JSON.parse(localStorage.getItem("bread"));
    },
  },

  mounted() {
	  /* Bus.$on('val', (data) => {
		console.log(data, 111)
		this.flag = data
	  }) */
	  
	  this.$forceUpdate(this.flag)
    this.$route.path != "/home" &&
      localStorage.getItem("bread") &&
      (this.breadData = JSON.parse(localStorage.getItem("bread")));
  },
};
</script>
<style lang="less" scoped>
.content {
  min-height: 500px;
  // background-color: #f5f5f5;
}
.home {
  background-color: #f5f5f5;
  margin-top: 166px;
}
.breadMain {
	width: 1200px;
  background-color: #f5f5f5;
  margin: 0 auto;
  display: flex;
  align-items: center;
  p {
    font-size: 14px;
    color: #333333;
	height: 24px;
	overflow: hidden;
    line-height: 24px;
    margin: 40px 0px;
  }
  img {
    width: 17px;
    height: 24px;
    margin-right: 10px;
  }
}
</style>
